//@ts-check
import React from 'react'
import { Paragraph } from 'components/typography'
import Blackboard from 'assets/icons/Blackboard'
import Notebook from 'assets/icons/Notebook'
import Video from 'assets/icons/Video'
import Image from 'assets/icons/Image'
import Bot from 'assets/icons/Bot'
import styles from './Tabs.module.css'

export const classroomTabIds = {
  blackboard: 'blackboard',
  notebook: 'notebook',
  photo: 'photo',
  video: 'video',
  barbeibot: 'barbeibot',
  none: 'none'
}
const OPTIONS = [
  {
    id: classroomTabIds.blackboard,
    label: 'Pizarra',
    Icon: Blackboard
  },
  {
    id: classroomTabIds.notebook,
    label: 'Cuadernillo',
    Icon: Notebook
  },
  {
    id: classroomTabIds.photo,
    label: 'Foto',
    Icon: Image
  },
  {
    id: classroomTabIds.barbeibot,
    label: 'Barbeibot',
    Icon: Bot
  },
  {
    id: classroomTabIds.video,
    label: 'Video',
    Icon: Video
  }
]
/**
 * @typedef ClassroomTabsProps
 * @property {'blackboard' | 'notebook' | 'photo' | 'barbeibot' | 'video' | 'none'} selectedOption
 * @property {'blackboard' | 'notebook' | 'photo' | 'barbeibot' | 'video' | 'none'} teacherOption
 * @property {boolean} showLabels
 * @property {boolean} disabled
 * @property {boolean} barbeibotAccess
 * @property {(id: string)=>void} onClick
 */
/**
 *
 * @param {Partial<ClassroomTabsProps>} props
 */
function ClassroomTabs({
  selectedOption = 'blackboard',
  teacherOption,
  showLabels = true,
  disabled = false,
  barbeibotAccess = false,
  onClick = () => 'blackboard'
}) {
  const tabCount = barbeibotAccess ? 5 : 4

  return (
    <div className={styles.tabs} data-tab-number={tabCount}>
      {OPTIONS.map(({ id, label, Icon }) => {
        if (!barbeibotAccess && id === classroomTabIds.barbeibot) return null
        return (
          <div
            key={id}
            className={[
              styles.itemContainer,
              id !== selectedOption ? styles.unfocused : '',
              disabled ? styles.disabled : ''
            ].join(' ')}
            onClick={() => !disabled && onClick(id)}
            data-status={disabled}
          >
            <div className={styles.iconContainer}>
              <Icon className={styles.icon} />
              {id === teacherOption && (
                <div
                  className={styles.matchingTab}
                  data-status={id === selectedOption}
                />
              )}
            </div>
            {showLabels && (
              <Paragraph type='body1Bold' className={styles.label}>
                {label}
              </Paragraph>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default ClassroomTabs
