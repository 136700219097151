import React from 'react'
import { useNavigate } from 'react-router-dom'
import config from 'config'
import { getAccessTimes, isTestAttendance } from 'utils'
import { H2 } from 'components/typography'
import HighlightCard from 'components/cards/highlight/HighlightCard'
import AttendanceHighlightCard from 'components/cards/highlight/AttendanceHighlightCard'
import Carousel from './Carousel'
import styles from './AttendanceCarousel.module.css'

function AttendanceCarousel({ targetTeacherIds, scheduledAttendances }) {
  const navigate = useNavigate()

  const currentDate = new Date().getTime()

  return (
    !targetTeacherIds && (
      <div className={styles.highlights}>
        <H2>Brain on & be cool 🚀</H2>

        <Carousel
          className={styles.carousel}
          infinite={false}
          autoplay={false}
          focusOnSelect
        >
          {scheduledAttendances.map((attendance, i) => {
            const { accessStart, accessEnd } = getAccessTimes(attendance)
            const isWithinAccessTime =
              currentDate >= accessStart && currentDate <= accessEnd

            if (isTestAttendance(attendance)) {
              if (accessEnd <= currentDate) return null
              return (
                <div className={styles.slide} key={attendance.id || i}>
                  <AttendanceHighlightCard
                    title={
                      i === 0
                        ? 'Tu 🥇 clase de prueba '
                        : 'Tu 🥈 clase de prueba '
                    }
                    date={attendance.classStart}
                    subjectName={attendance.subjectName}
                    buttonProps={{
                      onClick: () =>
                        navigate('/academy', {
                          state: attendance
                        }),
                      disabled: currentDate < accessStart
                    }}
                  />
                </div>
              )
            } else {
              if (currentDate > accessEnd) return null
              return (
                <div className={styles.slide} key={attendance.id || i}>
                  <AttendanceHighlightCard
                    title={
                      currentDate < attendance.classStart
                        ? 'No te pierdas tu próxima clase de'
                        : currentDate <= attendance.classEnd
                        ? 'Accede a tu clase de'
                        : 'Entra en tu clase anterior de'
                    }
                    date={attendance.classStart}
                    subjectName={attendance.subjectName}
                    buttonProps={{
                      onClick: () =>
                        navigate('/academy', {
                          state: attendance
                        }),
                      disabled: !isWithinAccessTime
                    }}
                  />
                </div>
              )
            }
          })}
          <div className={styles.slide} key='last'>
            <HighlightCard
              title='Conoce Classfy'
              imgUrl='https://picsum.photos/500/500?random=4.webp&blur=4'
              buttonProps={{ label: 'Ver', href: config.videoTourURL }}
            />
          </div>
        </Carousel>
      </div>
    )
  )
}

export default AttendanceCarousel
