import * as DateFns from 'date-fns'
import * as Locale from 'date-fns/locale'
import { pipe, trim } from 'ramda'

const {
  differenceInYears,
  parse,
  format,
  getWeek,
  startOfWeek,
  endOfWeek,
  addDays
} = DateFns

export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

export const DAY_NAME_BY_NUMBER = [
  { label: 'Lunes', day: 1 },
  { label: 'Martes', day: 2 },
  { label: 'Miércoles', day: 3 },
  { label: 'Jueves', day: 4 },
  { label: 'Viernes', day: 5 },
  { label: 'Sábado', day: 6 },
  { label: 'Domingo', day: 0 }
]

export const DEFAULT_UNIT_HOURS = {
  am: ['10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00'],
  pm: ['16:30-17:30', '17:30-18:30', '18:30-19:30', '19:30-20:30']
}

export const ONE_HOUR_IN_MILLISECONDS = 60 * 60 * 1000

/**
 *
 * @param {number} year
 * @param {number} month
 * @returns {Date[][]}
 */
export const getMonthDaysInfo = (year, month) => {
  let weeks = []
  const firstOfMonth = new Date(year, month, 1)
  const lastOfMonth = new Date(year, month + 1, 0)
  let day = firstOfMonth.getDay() - 1
  if (day === -1) day = 6
  let firstDayRendered = new Date(year, month, 1 - day)

  let inRange = true
  while (inRange) {
    weeks.push(
      // eslint-disable-next-line no-loop-func
      [0, 1, 2, 3, 4, 5, 6].map(i => {
        return new Date(
          firstDayRendered.getFullYear(),
          firstDayRendered.getMonth(),
          firstDayRendered.getDate() + i
        )
      })
    )
    if (weeks[weeks.length - 1][6] >= lastOfMonth) break

    firstDayRendered = new Date(
      firstDayRendered.getFullYear(),
      firstDayRendered.getMonth(),
      firstDayRendered.getDate() + 7
    )
  }
  return weeks
}

export function getFullWeekByStartOfWeek(StartOfWeek = new Date()) {
  return [0, 1, 2, 3, 4, 5, 6].map(i => {
    return new Date(
      StartOfWeek.getFullYear(),
      StartOfWeek.getMonth(),
      StartOfWeek.getDate() + i,
      0,
      0,
      0
    )
  })
}

export const isSameDay = (date1, date2) => {
  if (!date1 || !date2) return false
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  )
}

export function isPast(date1, date2) {
  if (!date1 || !date2) return false

  if (
    date1.getFullYear() < date2.getFullYear() ||
    date1.getMonth() < date2.getMonth()
  )
    return true
  if (date1.getFullYear() === date2.getFullYear()) {
    if (date1.getMonth() === date2.getMonth())
      return date1.getDate() < date2.getDate()
    return false
  }
  return false
}

export const dateFromObjectId = _id => {
  return new Date(parseInt(_id.substring(0, 8), 16) * 1000)
}

export const dateToString = dateOriginal => {
  if (dateOriginal === null || dateOriginal === undefined) return ''
  let date = new Date(dateOriginal)
  if (date) {
    let day = date.getDate() + ''
    if (day.length === 1) day = '0' + day
    let month = date.getMonth() + 1 + ''
    if (month.length === 1) month = '0' + month
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }
  return ''
}

export const dateToStringV2 = dateOriginal => {
  if (dateOriginal === null || dateOriginal === undefined) return ''
  let date = new Date(dateOriginal)
  if (date) {
    let day = date.getDate() + ''
    if (day.length === 1) day = '0' + day
    let month = date.getMonth() + 1 + ''
    if (month.length === 1) month = '0' + month
    const year = date.getFullYear()
    return `${year}-${month}-${day}`
  }
  return ''
}

export const toTimeString = value => {
  if (!value) return ''
  if (typeof value === 'number') value = new Date(value)
  value = new Date(value)
  let hour = value.getHours() + ''
  if (hour.length === 1) hour = '0' + hour
  let minute = value.getMinutes() + ''
  if (minute.length === 1) minute = '0' + minute
  return `${hour}:${minute}`
}

export const dateTimeToString = dateOriginal => {
  if (dateOriginal === null || dateOriginal === undefined) return ''
  let date = new Date(dateOriginal)
  if (date) {
    let day = date.getDate() + ''
    if (day.length === 1) day = '0' + day
    let month = date.getMonth() + 1 + ''
    if (month.length === 1) month = '0' + month
    const year = date.getFullYear()

    let hours = date.getHours() + ''
    if (hours.length === 1) hours = '0' + hours
    let minutes = date.getMinutes() + ''
    if (minutes.length === 1) minutes = '0' + minutes

    return `${day}/${month}/${year} ${hours}:${minutes}`
  }
  return ''
}

export const dateFromString = string => {
  if (string === undefined || string === null) return null
  if (string.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/)) {
    let data = string.split('/')
    let year = +data[2]
    let month = +data[1] - 1
    let day = +data[0]
    return new Date(year, month, day, 0, 0, 0, 0)
  } else return null
}

export const getExpirationDate = (month, year) => {
  if (month < 10) return `0${month}/${year}`
  return `${month}/${year}`
}

export const getCurrentMonth = () => {
  const now = new Date()
  return {
    month: now.getMonth() + 1,
    year: now.getFullYear()
  }
}

export const mergeHours = sortedHours => {
  const newList = []
  for (let i = 0; i < sortedHours.length; i++) {
    if (i === 0) {
      newList.push(sortedHours[i])
      continue
    }
    const previousEnds = newList[newList.length - 1].substr(-5)
    const nextBegins = sortedHours[i].substr(0, 5)
    if (previousEnds === nextBegins)
      newList[newList.length - 1] = newList[newList.length - 1]
        .slice(0, -5)
        .concat(sortedHours[i].substr(-5))
    else newList.push(sortedHours[i])
  }
  return newList
}

const MIN_AGE = 18

export const isAnAdult = (dateLeft, dateRight) => {
  const formattedDate = dateToString(dateLeft)

  return (
    differenceInYears(
      dateRight ? new Date(dateRight) : new Date(),
      parse(formattedDate, 'dd/MM/yyyy', new Date())
    ) > MIN_AGE
  )
}

export function formatDate({
  date = new Date(),
  stringFormat = '',
  options = {}
}) {
  if (options?.locale) options.locale = Locale[options.locale]
  else options.locale = Locale['es']
  return format(date, stringFormat, options)
}

export const formatDay = date => {
  const day = date.getDate()
  const month = MONTHS[date.getMonth()]
  return `${day} de ${month}`
}
/**
 *
 * @param {Date} date
 * @param {string} value x.e: 11:00
 */
export function makeCustomDate(date, value) {
  if (!value) return
  const [hour, minutes] = value.split(':')
  return new Date(new Date(date).setHours(Number(hour), Number(minutes), 0, 0))
}

export function makeClassStart() {
  let date = new Date()
  let aux = new Date(date)
  const minutes = aux.getMinutes()
  if (minutes < 15) date.setMinutes(0, 0, 0)
  if (minutes >= 15 && minutes <= 45) date.setMinutes(30, 0, 0)
  if (minutes > 45) date.setHours(aux.getHours() + 1, 0, 0, 0)
  return date
}
export function makeClassEnd(classStart = new Date()) {
  let newDate = new Date(classStart)
  newDate.setHours(classStart.getHours() + 1)
  newDate.setMinutes(0, 0, 0)
  return newDate
}

export function getWeekNumber(date = new Date()) {
  return getWeek(date, { weekStartsOn: 1 })
}
export function getStartOfWeek(date = new Date()) {
  return startOfWeek(date, { weekStartsOn: 1 })
}
export function getEndOfWeek(date = new Date()) {
  return endOfWeek(date, { weekStartsOn: 1 })
}
export function addDaysTo(date = new Date(), amount = 0) {
  return addDays(date, amount)
}

export function getDaysInMonth(date = new Date()) {
  return DateFns.getDaysInMonth(new Date(date))
}

/**
 *
 * @param {string} stringHour "00:00"
 * @returns {{hour: number, minute:number}}
 */
export const extractHourAndMinute = stringHour => {
  const [h = 0, m = 0] = stringHour.split(':')
  return {
    hour: Number(h),
    minute: Number(m)
  }
}
/**
 *
 * @param {string} stringHour "00:00 - 01:00"
 * @returns {{minHour: number, minMinutes:number, maxHour:number, maxMinutes:number}}
 */
export const extractRangeHoursAndMinutes = stringHour => {
  const [[minHour = 0, minMinutes = 0], [maxHour = 0, maxMinutes = 0]] =
    stringHour.split('-').map(pipe(trim, sh => sh.split(':')))
  return {
    minHour: Number(minHour),
    minMinutes: Number(minMinutes),
    maxHour: Number(maxHour),
    maxMinutes: Number(maxMinutes)
  }
}
