import React from 'react'
import { H2 } from 'components/typography'
import YouTubeCard from 'components/cards/highlight/MediaCard'
import HighlightCard from 'components/cards/highlight/HighlightCard'
import Carousel from './Carousel'
import styles from './YoutubeCarousel.module.css'

const YoutubeCarousel = ({ videos = [] }) => {
  return (
    <div className={styles.container}>
      <H2>Videos de YouTube 🎁</H2>
      <Carousel className={styles.carousel}>
        {videos.map(({ title, background, category, link, detail }, i) => (
          <div className={styles.slide} key={i}>
            <YouTubeCard
              index={i}
              imgUrl={background}
              title={title}
              href={link}
              category={category}
              detail={detail}
            />
          </div>
        ))}
        <div className={styles.slide} key='last'>
          <HighlightCard
            title='Descubre más en nuestro canal de YouTube'
            buttonProps={{
              size: 'tiny',
              type: 'accent-tertiary',
              href: 'https://www.youtube.com/c/MundoestudianteESPA%C3%91A/videos'
            }}
          />
        </div>
      </Carousel>
    </div>
  )
}

export default YoutubeCarousel
