//@ts-check
import { useCallback, useEffect, useState } from 'react'
import { hasPath, sortBy, take } from 'ramda'
import { getAccessTimes, prepareAttendances, shuffle } from 'utils'
import { useAuth } from 'context/AuthProvider'
import { getScheduledAttendances } from 'api/attendances'
import { getSampleTeachers } from 'api/teachers'
import useSubjects from './useSubjects'

const EXAMPLE_LIMIT = 10

const targetTeacherIds = process.env.REACT_APP_TEACHER_IDS || false

export default function useHome() {
  const [state, setState] = useState({
    teachers: [],
    canBookAClass: false,
    scheduledAttendances: [],
    blogPosts: [],
    youTubeVideos: [],
    tiktoks: []
  })
  const { student } = useAuth()
  const { subjectsById } = useSubjects()

  const {
    teachers,
    canBookAClass,
    scheduledAttendances,
    blogPosts,
    youTubeVideos,
    tiktoks
  } = state

  const repetitiveCalls = useCallback(async () => {
    try {
      const scheduledAttendances = await getScheduledAttendances()

      const teachers = await getSampleTeachers()
        .then(teachers =>
          !targetTeacherIds
            ? teachers
            : teachers.filter(({ id }) => targetTeacherIds.includes(id))
        )
        .then(sortBy(teachers => !hasPath(['isOnline'], teachers)))
        .then(teachers => {
          const { prevAttendance, currentAttendance, nextAttendance } =
            prepareAttendances(scheduledAttendances)

          const firstTeachers = teachers.filter(({ id }) =>
            [
              prevAttendance?.teacherId,
              currentAttendance?.teacherId,
              nextAttendance?.teacherId
            ].includes(id)
          )
          const otherTeachers = teachers.filter(
            ({ id }) =>
              ![
                prevAttendance?.teacherId,
                currentAttendance?.teacherId,
                nextAttendance?.teacherId
              ].includes(id)
          )

          return take(
            EXAMPLE_LIMIT,
            firstTeachers.concat(shuffle(otherTeachers))
          ).map(teacher => {
            const currentDate = new Date()

            const { accessStart: currentStart, accessEnd: currentEnd } =
              getAccessTimes(currentAttendance)
            const { accessStart: nextStart } = getAccessTimes(nextAttendance)
            const { accessEnd: prevEnd } = getAccessTimes(prevAttendance)

            const canAccessToCurrentClass =
              currentAttendance?.teacherId === teacher.id &&
              currentDate.getTime() >= currentStart &&
              currentDate.getTime() <= currentEnd

            const canAccessToNextClass =
              nextAttendance?.teacherId === teacher.id &&
              nextStart <= currentDate.getTime()

            const canAccesToPrevClass =
              prevAttendance?.teacherId === teacher.id &&
              prevEnd >= currentDate.getTime()

            const isAvailable = () => {
              if (canAccessToCurrentClass) return true
              if (canAccessToNextClass) return true
              if (canAccesToPrevClass) return true
              return false
            }
            const getAttendance = () => {
              if (canAccessToCurrentClass) return currentAttendance
              if (canAccessToNextClass) return nextAttendance
              if (canAccesToPrevClass) return prevAttendance
              return { teacherId: teacher.id }
            }
            teacher.attendance = getAttendance()
            teacher.available = !targetTeacherIds ? isAvailable() : true

            return teacher
          })
        })

      setState(state => ({
        ...state,
        teachers,
        scheduledAttendances
      }))
    } catch (error) {
      console.error('Error in repetitiveCalls: ', error)
    }
  }, [])

  const fetchCommonData = useCallback(async () => {
    try {
      setState(state => ({
        ...state,
        canBookAClass: false //student.isNewUser && !testAttendanceCount
      }))
    } catch (error) {
      console.error('Error in fetchCommonData: ', error)
    }
  }, [])

  useEffect(() => {
    fetchCommonData()
    repetitiveCalls()
    if (targetTeacherIds) return
    const interval = setInterval(() => {
      repetitiveCalls()
    }, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [fetchCommonData, repetitiveCalls])

  useEffect(() => {
    fetch('https://mundoestudiante-data.netlify.app/classfy-home.json')
      .then(res => {
        if (!res.ok) throw new Error('Error fetching classfy-hom data')
        return res.json()
      })
      .then(data => {
        setState(prev => ({
          ...prev,
          blogPosts: getElementsBy(data.blog),
          youTubeVideos: getElementsBy(data.youtube),
          tiktoks: getElementsBy(data.tiktok)
        }))
      })
      .catch(_ => null)
  }, [])
  return {
    scheduledAttendances,
    teachers,
    canBookAClass,
    subjectsById,
    targetTeacherIds,
    blogPosts,
    youTubeVideos,
    tiktoks
  }
}

function getElementsBy(list = [], day = new Date().getDate()) {
  const startIndex = ((day || 1) - 1) % list.length
  return list
    .slice(startIndex, startIndex + 4)
    .concat(list.slice(0, Math.max(0, startIndex + 4 - list.length)))
}
