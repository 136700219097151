//@ts-check
import React from 'react'
import useHome from 'hooks/useHome'
import AttendanceCarousel from 'components/carousel/AttendanceCarousel'
import TeacherCarousel from 'components/carousel/TeacherCarousel'
import BlogCarousel from 'components/carousel/BlogCarousel'
import YoutubeCarousel from 'components/carousel/YoutubeCarousel'
import TikTokCarousel from 'components/carousel/TikTokCarousel'
import styles from './Home.module.css'

function Home() {
  const {
    scheduledAttendances,
    teachers,
    canBookAClass,
    subjectsById,
    targetTeacherIds,
    blogPosts,
    youTubeVideos,
    tiktoks
  } = useHome()
  return (
    <section className={styles.container}>
      <AttendanceCarousel
        targetTeacherIds={targetTeacherIds}
        scheduledAttendances={scheduledAttendances}
      />
      <TeacherCarousel
        teachers={teachers}
        canBookAClass={canBookAClass}
        subjectsById={subjectsById}
      />
      <BlogCarousel posts={blogPosts} />
      <YoutubeCarousel videos={youTubeVideos} />
      <TikTokCarousel tiktoks={tiktoks} />
    </section>
  )
}

export default Home
