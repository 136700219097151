export async function* streamingFetch(fn) {
  const response = await fn()
  const reader = response.body.getReader()
  while (true) {
    const { done, value } = await reader.read()

    if (done) break
    yield new TextDecoder().decode(value, { stream: true })
  }
}
