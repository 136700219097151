import * as React from 'react'
const Send = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 48 45'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      stroke={props.color || '#000'}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={5}
      d='M22.456 22.473H7.252m-.43 1.993L4.308 31.97c-1.375 4.11-2.063 6.164-1.57 7.43a3.75 3.75 0 0 0 2.486 2.248c1.308.365 3.284-.524 7.236-2.302l25.336-11.401c3.858-1.736 5.786-2.604 6.382-3.81a3.75 3.75 0 0 0 0-3.324c-.596-1.206-2.524-2.074-6.382-3.81l-25.38-11.42C8.478 3.808 6.508 2.92 5.2 3.285a3.75 3.75 0 0 0-2.485 2.243c-.496 1.263.185 3.314 1.546 7.414l2.565 7.73c.234.704.35 1.056.397 1.416.04.32.04.643-.001.963-.047.36-.165.711-.4 1.415Z'
    />
  </svg>
)
export default Send
