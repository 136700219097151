export const SubjectTypes = {
  CIENCIAS: 'Ciencias',
  LETRAS: 'Letras'
}

export const SubjectSubtypes = {
  Ciencias: { CIENCIAS: 'Ciencias', CIENCIAS_SALUD: 'Ciencias de la salud' },
  Letras: {
    LETRAS: 'Letras',
    IDIOMAS: 'Idiomas',
    ARTES: 'Artes',
    OTRAS: 'Otras'
  }
}
export const SubjectLevels = {
  PRIMARIA: 'Primaria',
  SECUNDARIA: 'Secundaria',
  BACHILLERATO: 'Bachillerato',
  FP: 'FP',
  UNIVERSIDAD: 'Universidad',
  IDIOMAS: 'Idiomas',
  OTRAS: 'Otras'
}

export const International = { INTERNACIONAL: 'Internacional' }

export const SubjectSublevels = {
  Idiomas: { A1: 'A1', A2: 'A2', B1: 'B1', B2: 'B2', C1: 'C1', C2: 'C2' },
  Secundaria: International,
  Bachillerato: International
}

export const AttendanceStatusLabels = {
  planned: 'Planificada',
  imparted: 'Impartida',
  justified_absence: 'Falta jusificada',
  absence: 'Falta',
  canceled_by_app: 'Cancelado por el sistema'
}
export const AttendanceTypeLabels = {
  normal: 'Normal',
  recovery: 'Recuperación',
  free: 'Gratuita',
  promotion: 'Promoción'
}
export const AttendanceTypes = {
  NORMAL: 'NORMAL',
  PLANA: 'PLANA',
  INTENSIVO_PROMOCION: 'INTENSIVO_PROMOCION',
  ESPECIAL: 'ESPECIAL',
  CAMBIO: 'CAMBIO',
  PACK: 'PACK',
  PACK_PROMOCION: 'PACK_PROMOCION',
  GRATUITA: 'GRATUITA',
  PRUEBA: 'PRUEBA',
  RECUPERACION: 'RECUPERACION',
  INTENSIVO: 'INTENSIVO',
  PROMOCION: 'PROMOCION',
  EXTRA: 'EXTRA',
  FORMACION: 'FORMACION'
}
export const AttendanceStatus = {
  PLANNED: 'planned',
  IMPARTED: 'imparted',
  JUSTIFIED_ABSENCE: 'justified_absence',
  ABSENCE: 'absence',
  CANCELED_BY_APP: 'canceled_by_app'
}
export const TicketStatusLabels = {
  open: 'Abierto',
  closed: 'Cerrado'
}
export const TicketTypes = {
  REGISTER: 'register',
  APP_HELP: 'appHelp',
  BUDGET: 'budget',
  QUESTION: 'question'
}

export const TicketTypeLabels = {
  register: 'Registro',
  appHelp: 'Ayuda',
  budget: 'Propuesta',
  question: 'Duda general'
}
export const AccessTypes = {
  STUDENT: 'student',
  TRIAL: 'trial',
  INTERNAL: 'internal'
}
export const AccessTypeLabels = {
  student: 'Alumno',
  trial: 'Prueba',
  internal: 'Prueba mundoestudiante'
}

export const SignedByTypes = {
  STUDENT: 'student',
  TUTOR: 'tutor'
}
export const SignedByLabels = {
  student: 'Estudiante',
  tutor: 'Tutor'
}

export const SubscriptionConfigTypes = { AGORA: 'agora' }

export const SubscriptionCodes = {
  NORMAL: 'normal',
  MORNINGS: 'mornings',
  PACK: 'pack',
  OTHERS: 'others'
}
export const SubscriptionCodeLabels = {
  normal: 'Tarifa refuerza',
  mornings: 'Tarifa mañanas',
  pack: 'Pack de horas',
  others: 'Otros'
}

export const GracePeriod = {
  test: { init: 30, end: 30 },
  standard: { init: 3, end: 15 }
}

export const ENGINES = {
  gpt: 'gpt',
  claude: 'claude',
  gemini: 'gemini'
}

export const ENGINE_LABELS = {
  gpt: 'Borja-bot',
  claude: 'Mamen-bot',
  gemini: 'Daniel-bot'
}
