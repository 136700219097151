//@ts-check
import { useState } from 'react'
import { syncBarbeibotAnswer, syncBarbeibotQuestion } from 'api/sockets'
import { ENGINES } from 'utils/constants'
import { imageUrlToBase64 } from 'utils/files'
import { streamingFetch } from 'utils/request'

function useBarbeibot({ socket, teacher }) {
  const [state, setState] = useState({
    engine: ENGINES.gpt,
    gpt: { image: null, mediaType: null, message: null, isLoading: false },
    claude: { image: null, mediaType: null, message: null, isLoading: false },
    gemini: { image: null, mediaType: null, message: null, isLoading: false }
  })
  const { engine, gpt, claude, gemini } = state
  const handleSubmit = async question => {
    try {
      setState(prev => ({
        ...prev,
        [engine]: { ...prev[engine], isLoading: true, message: '' }
      }))
      const imageLocation = state[engine].image
      const base64Image = await imageUrlToBase64(imageLocation)

      syncBarbeibotQuestion({
        socket,
        teacher,
        imageLocation,
        question
      })

      for await (let chunk of streamingFetch(async () =>
        fetch(
          'https://2egtqm3fn7zw6xvvwpsgzhw6sy0kzpbh.lambda-url.eu-west-1.on.aws/',
          {
            method: 'POST',
            body: JSON.stringify({
              question,
              image: base64Image,
              mediaType: state[engine].mediaType,
              engine
            })
          }
        )
      )) {
        setState(prev => ({
          ...prev,
          [engine]: {
            ...prev[engine],
            message: prev[engine].message + chunk
          }
        }))
        syncBarbeibotAnswer({ socket, teacher, chunk })
      }
    } catch (error) {
      console.error('Error solving', error.message)
    } finally {
      setState(prev => ({
        ...prev,
        [engine]: { ...prev[engine], isLoading: false }
      }))
    }
  }

  const handleDrop = (image, mediaType) =>
    setState(prev => ({
      ...prev,
      [engine]: { ...prev[engine], image, mediaType }
    }))

  const handleChangeEngine = engine => setState(prev => ({ ...prev, engine }))

  return {
    engine,
    gpt,
    claude,
    gemini,
    handleChangeEngine,
    handleDrop,
    handleSubmit
  }
}

export default useBarbeibot
