//@ts-check
import React, { useEffect, useState } from 'react'
import Gear from 'assets/icons/Gear'
import useMediaSetup from 'hooks/useMediaSetup'
import Modal from 'components/modals/Modal'
import { H3 } from 'components/typography'
import Select from 'components/selects/Select'
import Button from 'components/buttons/Button'
import VideoSource from 'components/academy/classroom/VideoSource'
import styles from './MediaSetup.module.css'

function MediaSetup({
  className = '',
  fabIconColor = 'var(--dark-grey-40)',
  onSave
}) {
  const [showSetup, setShowSetup] = useState(false)
  const {
    isLoading,
    micLevelRef,
    mediaRef,
    audioContextRef,
    audioInputs,
    audioOutputs,
    videoInputs,
    selectedMedia,
    start,
    handleChange
  } = useMediaSetup()

  const handleSaveSetup = () => {
    onSave(selectedMedia)
    setShowSetup(false)
  }

  useEffect(() => {
    const ref = mediaRef.current
    const audioCtxRef = audioContextRef?.current
    if (!showSetup) {
      if (ref?.srcObject)
        ref.srcObject.getTracks().forEach(track => {
          track.stop()
        })
      if (audioCtxRef && audioCtxRef.state !== 'closed') audioCtxRef.close()
      return
    }
    start()
    return () => {
      if (ref?.srcObject) {
        ref.srcObject.getTracks().forEach(track => {
          track.stop()
        })
        if (audioCtxRef && audioCtxRef.state !== 'closed') audioCtxRef.close()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSetup])

  const hasAudioOutput = 'sinkId' in HTMLMediaElement.prototype
  const disabled =
    !selectedMedia.audioInputId ||
    (hasAudioOutput ? !selectedMedia.audioOutputId : false) ||
    isLoading
  return (
    <>
      <Gear
        className={[styles.gearIcon, className].join(' ')}
        color={fabIconColor}
        onClick={() => setShowSetup(true)}
      />
      {showSetup && (
        <Modal
          onCancel={() => setShowSetup(false)}
          showActions={false}
          showCloseIcon
        >
          <div className={styles.modal}>
            <H3>Selecciona tu configuración preferida</H3>
            <VideoSource ref={mediaRef} muted />
            <div className={styles.setupSection}>
              <Select
                name='audioInputId'
                label={
                  <div className={styles.micLabel}>
                    Micrófono
                    <div className={styles.micLevelMeter}>
                      <div ref={micLevelRef} className={styles.micLevelBar} />
                    </div>
                  </div>
                }
                options={audioInputs}
                value={selectedMedia.audioInputId}
                isLoading={isLoading}
                onChange={handleChange}
                menuPlacement={hasAudioOutput ? 'auto' : 'top'}
              />
              <Select
                name='videoInputId'
                label='Cámara'
                options={videoInputs}
                value={selectedMedia.videoInputId}
                isLoading={isLoading}
                onChange={handleChange}
                menuPlacement={hasAudioOutput ? 'auto' : 'top'}
              />
              {hasAudioOutput && (
                <Select
                  name='audioOutputId'
                  label='Altavoz '
                  options={audioOutputs}
                  value={selectedMedia.audioOutputId}
                  isLoading={isLoading}
                  onChange={handleChange}
                  menuPlacement={audioOutputs.length > 2 ? 'top' : 'auto'}
                />
              )}
            </div>
            <Button
              size='tiny'
              type='secondary'
              disabled={disabled}
              loading={isLoading}
              onClick={handleSaveSetup}
            />
          </div>
        </Modal>
      )}
    </>
  )
}

export default MediaSetup
