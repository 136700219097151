//@ts-check
import React, { useEffect, useState } from 'react'
import { isEmpty } from 'ramda'
import Markdown from 'react-markdown'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import { requestImageList } from 'api/sockets'
import useBarbeibot from 'hooks/useBarbeibot'
import ImageIcon from 'assets/icons/Image'
import Blackboard from 'assets/icons/Blackboard'
import Send from 'assets/icons/Send'
import CoolAndSmart from 'assets/svg/CoolAndSmart'
import { H2, H5, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import TextArea from 'components/inputs/TextArea'
import DotsSpinner from 'components/spinners/DotsSpinner'
import GalleryModal from 'components/modals/GalleryModal'
import { formatDay } from 'utils/date'
import { getMimeTypeFromUrl } from 'utils/files'
import styles from './ClassroomBot.module.css'
import 'katex/dist/katex.min.css'

const ClassroomBot = ({ socket, student, teacher, isVisible }) => {
  const [step, setStep] = useState(0)
  const [question, setQuestion] = useState('')
  const [showZoom, setShowZoom] = useState(false)
  const { gpt, handleDrop, handleSubmit } = useBarbeibot({ socket, teacher })

  const handleSelectImage = (image, mediaType) => {
    handleDrop(image, mediaType)
    setStep(0)
  }

  const formattedContent = (gpt.message || '')
    .replaceAll('\\[', '$$')
    .replaceAll('\\]', '$$')
    .replaceAll('\\(', '$')
    .replaceAll('\\)', '$')
  const photo = gpt.image
  const isLoading = gpt.isLoading
  return (
    <div className={styles.container} hidden={!isVisible}>
      <div className={styles.innerContainer}>
        {step === 1 && (
          <ImageSync
            socket={socket}
            student={student.id}
            onImageSelect={handleSelectImage}
            onBack={() => setStep(0)}
          />
        )}
        {step === 0 && (
          <div
            className={[styles.bot, !photo ? styles.centerHead : ''].join(' ')}
          >
            <div className={styles.head}>
              <CoolAndSmart
                className={photo ? styles.svgWithContent : styles.svg}
              />
              <H2>Resuelve tus dudas con Barbei-bot</H2>
              <Paragraph type='body1Bold'>
                Selecciona la foto sobre la que tienes dudas y haz tu pregunta
              </Paragraph>

              <Button
                label='Seleccionar'
                size='small'
                onClick={() => setStep(1)}
              />
            </div>

            {photo && (
              <div className={styles.questionArea}>
                <img
                  className={styles.img}
                  src={photo}
                  onClick={() => setShowZoom(true)}
                />
                <TextArea
                  placeholder='(Opcional) Dale a Barbei-bot algo más de información'
                  className={styles.textArea}
                  value={question}
                  onChange={e => setQuestion(e.target.value)}
                />
                {isLoading ? (
                  <div className={styles.spinner}>
                    <DotsSpinner />
                  </div>
                ) : (
                  <Send
                    className={[
                      styles.sendIcon,
                      isLoading ? styles.disabled : ''
                    ].join(' ')}
                    color={
                      !isLoading ? 'var(--seadapted)' : 'var(--disabled-color)'
                    }
                    onClick={() => !isLoading && handleSubmit(question)}
                  />
                )}
              </div>
            )}
            <Markdown
              className={styles.markdown}
              remarkPlugins={[remarkMath]}
              rehypePlugins={[rehypeKatex]}
            >
              {formattedContent}
            </Markdown>
          </div>
        )}
      </div>

      <div className={styles.tools}>
        <div />
        <div className={styles.right}>
          <div className={styles.innerRight}>
            {step === 0 ? (
              <div
                className={styles.icons}
                title='Elegir foto'
                onClick={() => setStep(1)}
              >
                <ImageIcon color='var(--dark-color)' />
              </div>
            ) : (
              <div
                className={[styles.icons, !photo ? styles.disabled : ''].join(
                  ' '
                )}
                title='Ver pizarra'
                onClick={() => !!photo && setStep(0)}
              >
                <Blackboard color='var(--dark-color)' />
              </div>
            )}
          </div>
        </div>
      </div>
      {showZoom && (
        <GalleryModal
          initialIndex={0}
          photos={[{ location: photo }]}
          onCancel={() => setShowZoom(false)}
        />
      )}
    </div>
  )
}

export default ClassroomBot

function ImageSync({ socket, student, onImageSelect, onBack }) {
  const [selectedImage, setSelectedImage] = useState(null)
  const [studentImages, setStudentImages] = useState([])

  useEffect(() => {
    setSelectedImage(null)
  }, [student, socket])

  useEffect(() => {
    if (!socket) return
    let isActive = true
    requestImageList({ socket, student })
      .then(images => {
        if (!isActive) return
        const sortedImages = images
          .map(image => ({
            ...image,
            timestamp: new Date(image.date).getTime(),
            day: formatDay(new Date(image.date))
          }))
          .sort((img1, img2) => img2.timestamp - img1.timestamp)
          .reduce((acc, curr) => {
            if (acc.length === 0) return [{ day: curr.day, elements: [curr] }]
            else if (acc[acc.length - 1].day === curr.day)
              return [
                ...acc.slice(0, -1),
                {
                  ...acc[acc.length - 1],
                  elements: [...acc[acc.length - 1].elements, curr]
                }
              ]
            else return [...acc, { day: curr.day, elements: [curr] }]
          }, [])
        setStudentImages(sortedImages)
      })
      .catch(console.error)
    return () => {
      isActive = false
    }
  }, [socket, student])

  const commonActions = (
    <div className={styles.imageSyncActions}>
      <Button type='secondary' size='small' label='Volver' onClick={onBack} />
      <Button
        label='Cargar'
        size='small'
        onClick={() =>
          onImageSelect(
            selectedImage.location,
            getMimeTypeFromUrl(selectedImage.location)
          )
        }
        disabled={!selectedImage}
      />
    </div>
  )
  if (!studentImages || isEmpty(studentImages))
    return (
      <div className={styles.step1Empty}>
        <H2>
          Todavía no se han compartido <br /> imágenes
        </H2>
        {commonActions}
      </div>
    )
  return (
    <div className={styles.step1}>
      <div className={styles.imageSync}>
        {studentImages.map(day => (
          <div className={styles.day} key={day.day}>
            <H5 className={styles.title}>{day.day}</H5>
            <div className={styles.content}>
              {day.elements.map((image, index) => (
                <img
                  className={[
                    styles.image,
                    selectedImage && selectedImage._id === image._id
                      ? styles.selected
                      : ''
                  ].join(' ')}
                  onClick={() => setSelectedImage(image)}
                  onDoubleClick={() => {
                    setSelectedImage(image)
                    onImageSelect(
                      image.location,
                      getMimeTypeFromUrl(image.location)
                    )
                  }}
                  key={index}
                  src={image.thumbnail}
                  title='Puedes dar doble clic'
                  alt='Foto de mundoestudiante'
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      {commonActions}
    </div>
  )
}
